import { createFooter, getLegalLinksFallback, getLegalLinks, LegalLinkNames, initDictionaryCatalog } from '@wg/wows-common-footer/main';
import { IFooterProps, Realm } from '@wg/wows-common-footer/types';
import { useEffect } from 'react';
import { settings } from '~/helpers/utils';
import styles from './Footer.scss';

const Footer = () => {
    useEffect(() => {
        async function initFooter() {
            await initDictionaryCatalog({ language: settings.languageCode, dictionaryCatalog: window.i18n.catalog });

            const linksConfig = getLegalLinks({ language: settings.languageCode, realm: settings.realm as Realm });
            delete linksConfig[LegalLinkNames.PARTNERS];
            const legalLinks = getLegalLinksFallback({ language: settings.languageCode, realm: settings.realm as Realm, linksConfig });

            const options: IFooterProps = {
                selector: '#footer-container',
                language: settings.languageCode,
                realm: settings.realm as Realm,
                version: '',
                dictionaryCatalog: window.i18n.catalog,
                rootUrl: null,
                legalLinks,
                onLanguageChange: ({ realm, language }) => {
                    location.href = `${settings.realmsMap[realm]}/${language}/`;
                },
                onClickByLink: (event: MouseEvent) => {
                    event.preventDefault();
                    const target = event.currentTarget as HTMLLinkElement;
                    if (target.id !== 'ot-sdk-btn') {
                        window.open(target.href, '_blank');
                    }
                },
            };

            if (settings.realm === 'cn360') {
                options.realms = [];
            }

            createFooter(options);

            if (window.OneTrust) {
                window.OneTrust.initializeCookiePolicyHtml();
            }
        }

        initFooter();
    }, []);

    return (
        <div id={'footer-container'} className={styles.footer} />
    );
}

export default Footer;