import * as React from 'react';
import classNames from 'classnames';
import styles from './Profile.scss';
import { IInventoryState, IResponse } from '~/interfaces';
import { imagesRoomLocalPath, isInGameBrowser } from '~/helpers/utils';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import RoomContent from '~/Components/Profile/RoomContent';
import SvgRoom from '~/Components/Profile/SvgRoom';
import ActiveDoll from '~/Components/ActiveDoll/ActiveDoll';
import MainProgress from '~/Components/MainProgress/MainProgress';
import Footer from '../Footer/Footer';

interface IProfile {
    isAuthorizedUser?: boolean;
    response: IResponse;
    inventory: IInventoryState;
    totalCount: number;
    availableCount: number;
}

const Profile = (props: IProfile) => {
    const backgroundClassNames = classNames(styles.background, {
        [styles.image]: !isInGameBrowser,
    });

    const backgroundStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/background.jpg)`,
          }
        : {};

    const progressCollected = new ProgressCollected(props.response, props.inventory);
    const mainProgressPercent = progressCollected.getMainProgress();

    const mainProgressPercentDisplay = progressCollected.getPercentForDisplay(mainProgressPercent, progressCollected.getAvailableCount());

    const mainClassName = `commonItem-${mainProgressPercentDisplay}`;
    const commonClassNames = classNames(styles.backgroundItem, {
        [styles[mainClassName]]: !isInGameBrowser,
    });

    const commonStyles = isInGameBrowser
        ? {
              backgroundImage: `url(${imagesRoomLocalPath}/images/common/common-${mainProgressPercentDisplay}.png)`,
          }
        : {};

    const classesBackgroundWrap = classNames(styles.backgroundWrap, {
        [styles.withFooter]: !isInGameBrowser,
    })

    return (
        <div className={styles.container}>
            <div className={classesBackgroundWrap}>
                <div className={backgroundClassNames} style={backgroundStyles}>
                    <RoomContent progressCollected={progressCollected} />
                    <div className={commonClassNames} style={commonStyles} />
                    {mainProgressPercent > 50 && <div className={classNames(styles.backgroundItem, styles.cat)} />}
                    <ActiveDoll />
                    <div className={classNames(styles.backgroundItem, styles.light)} />
                    <div className={classNames(styles.backgroundItem, styles.backgroundShadows)} />
                    <SvgRoom progressCollected={progressCollected} />
                    <MainProgress totalCount={props.totalCount} availableCount={props.availableCount} percentProgressCollected={progressCollected.getMainProgress()} />
                </div>
                {!isInGameBrowser && <Footer />}
            </div>
        </div>
    );
};

export default React.memo(Profile);
